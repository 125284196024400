import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Specialties = () => {
  const data = useStaticQuery(graphql`
    query {
      chronicPain: file(relativePath: { eq: "chronicPain.png" }) {
        childImageSharp {
          fixed(width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      healthy: file(relativePath: { eq: "healthy.jpg" }) {
        childImageSharp {
          fixed(width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      womensHealth: file(relativePath: { eq: "womensHealth.png" }) {
        childImageSharp {
          fixed(width: 320) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Fitness and Spirit Services" />
      <div className="container-main">
        <div className="container-main_div">
          <h1>Specialties</h1>

          <h2>Women’s Health 101</h2>
          <Img
            alt="Fitness and Spirit"
            fixed={data.womensHealth.childImageSharp.fixed}
            style={{
              margin: '40px auto',
              textAlign: 'center',
              display: 'block',
            }}
          />
          <p>
            Are you seeking answers for hormone balance, thyroid support, gut
            health, ways to decrease your stress, menopause, or weight loss?
            Look no further! Along my own journey which started as a teen, I
            struggled with all of the above and more. Twenty plus years later,
            after experimenting, hundreds of doctor visits, learning, testing,
            and even many surgical procedures, I stand my strongest and
            healthiest! When supporting women’s health and hormones, there are
            several areas that one requires focusing on- such as, stress
            management, mental health, sleep, nutrition, exercise, environment,
            relationship building, and more.
          </p>

          <h2>Hypertension and Diabetes</h2>
          <Img
            alt="Fitness and Spirit"
            fixed={data.healthy.childImageSharp.fixed}
            style={{
              margin: '40px auto',
              textAlign: 'center',
              display: 'block',
            }}
          />
          <p>
            Does heart disease or diabetes run in your family? Have you just
            been diagnosed with either and not sure how to make changes? Have
            you been living with either, or both, and have been unsuccessful
            with changing your lifestyle? You are NOT alone! Together, we can
            design individualized S.M.A.R.T. Goals to create healthy changes for
            your NEW healthy lifestyle.
          </p>
          <h2>According to the American Heart Association:</h2>
          <p>
            Nearly half of all U.S. adults have some type of cardiovascular
            disease, a percentage that reflects recently updated guidelines for
            treating high blood pressure, according to a new report. High blood
            pressure – also known as hypertension – can lead to heart attack,
            heart failure and stroke. This year's report said 121.5 million
            adults in the U.S. – 48 percent based on 2016 figures – have
            cardiovascular disease. Heart disease was the No. 1 cause of death
            in the U.S. and stroke was No. 5, the same ranking as in the
            previous year.
          </p>
          <h2>
            Key findings from the National Diabetes Statistics Report and the
            CDC The report finds that:
          </h2>
          <ul>
            <li className="text-base leading-relaxed">
              In 2015, an estimated 1.5 million new cases of diabetes were
              diagnosed among people ages 18 and older.
            </li>
            <li className="text-base leading-relaxed">
              Nearly 1 in 4 four adults living with diabetes – 7.2 million
              Americans – didn’t know they had the condition. Only 11.6 percent
              of adults with prediabetes knew they had it.
            </li>
            <li className="text-base leading-relaxed">
              Rates of diagnosed diabetes increased with age. Among adults ages
              18-44, 4 percent had diabetes. Among those ages 45-64 years, 17
              percent had diabetes. And among those ages 65 years and older, 25
              percent had diabetes.
            </li>
          </ul>
          <h2>Functional Living with Chronic Pain</h2>
          <Img
            alt="Fitness and Spirit"
            fixed={data.chronicPain.childImageSharp.fixed}
            style={{
              margin: '40px auto',
              textAlign: 'center',
              display: 'block',
            }}
          />
          <p>
            Are you tired of experiencing a debilitating and invisible disease
            holding you back from living a functional life with ease? As a
            person being diagnosed with chronic pain at a young age of 21 and
            later with fibromyalgia at 27, I know all too well the destructive
            thought forms and severe pain that can cripple you!
          </p>
          <p>
            I embody the meaning behind the “rising phoenix.” To rise from deep
            despair, darkness, and ash like a phoenix...I emerge from
            catastrophe wiser, stronger, and more powerful. I have hit my rock
            bottom, being close to bed-ridden, and now I live my functional life
            with ease. This is not to say that I never experience painful
            flare-ups, however, I can now say that I am now thriving and not
            just surviving!
          </p>
          <p>
            Allow me to equip you with the tools, facilitate change, & create
            healing together!
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Specialties
